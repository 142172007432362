import {Link} from "react-router-dom";
import {clsx} from "clsx";
import Container from "../components/ui/Container";
import {useFocus} from "../lib/hooks";

const offerTextStyle = clsx(
    "flex flex-col items-left py-6 px-12",
);

const offerHeaderStyle = clsx(
    'text-2xl',
);

const Offer = () => {
    const containerRef = useFocus('/offer');
    return(
        <Container  className={clsx(offerTextStyle)}>
            <div tabIndex="-1" ref={containerRef}>
                <h1 className={clsx(offerHeaderStyle)}>Публичная оферта</h1>
                <h3>Предложение о порядке продажи товаров дистанционным способом в интернет-магазине Timshee (в
                    соответствии
                    с Правилами продажи товаров дистанционным способом)</h3>

                <h4>1. Термины и определения</h4>

                <p><>1.1. Интернет-магазин (Сайт)</> — торговая площадка Продавца, расположенная в сети
                    интернет
                    по адресу <Link to="https://timshee.ru">timshee.ru</Link>, на которой осуществляется продажа товаров
                    дистанционным способом.</p>

                <p><>1.2. Заказ</> — должным образом оформленный запрос Покупателя Продавцу на заключение
                    договора розничной купли-продажи Товаров.</p>

                <p><>1.3. Продавец</> — ИП Шибаев Тимур Олегович.</p>
                <ul>
                    <li>ИНН 164600651958</li>
                    <li>ОГРН 319169000099322</li>
                    <li>Юридический адрес: 420081, РОССИЯ, РЕСП ТАТАРСТАН, Г КАЗАНЬ, УЛ ШУРТЫГИНА, Д 11, КВ 43
                    </li>
                </ul>

                <p><>1.4. Покупатель (Клиент)</> — посетитель Сайта, разместивший Заказ в Интернет-магазине
                    Timshee.</p>

                <p><>1.5. Товар</> — товар, представленный к продаже на Сайте.</p>

                <p><>1.6. Служба доставки</> — третье лицо, оказывающее по договору с Продавцом услуги по
                    доставке заказов клиентам.</p>

                <p><>1.7. Акция</> — мероприятие, направленное на увеличение объемов продаж, привлечение
                    внимания потребителей к продукции, проводимое в соответствии с правилами, размещенными на Сайте, и
                    ограниченное по срокам проведения.</p>

                <p><>1.8. Оператор</> — сотрудник колл-центра Продавца.</p>
            </div>
            <div>
                <h4>2. Общие положения</h4>
                <p><>2.1. Настоящее предложение, а также информация о товаре, представленная на сайте, являются
                    публичной офертой в соответствии со ст. 435 и ч. 2 ст. 437 ГК РФ.</></p>

                <p><>2.2. К отношениям между покупателем (клиентом) и продавцом применяются положения ГК РФ о
                    розничной купле-продаже (§ 2 глава 30), а также Закона РФ от 07.02.1992 N 2300-1 «О защите прав
                    потребителей» и Постановления Правительства РФ от 27.09.2007 N 612 «Об утверждении Правил продажи
                    товаров дистанционным способом» и иные правовые акты, принятые в соответствии с ними.</></p>

                <p><>2.3. Продавец осуществляет продажу Товаров через Интернет-магазин любому Покупателю,
                    оформившему
                    Заказ на условиях настоящей оферты.</></p>

                <p><>2.4. Настоящая оферта считается принятой Покупателем с момента оформления первого Заказа на
                    Сайте
                    (с использованием регистрации на Сайте в качестве постоянного покупателя Продавца либо без таковой
                    регистрации, в том числе при оформлении Заказа через оператора).</></p>

                <p><>2.5. Договор розничной купли-продажи считается заключенным с момента выдачи Продавцом
                    Покупателю
                    кассового или товарного чека либо иного документа, подтверждающего оплату товара.</></p>

                <p><>2.6. Качество товара, его безопасность для жизни, здоровья потребителей, окружающей среды,
                    подтверждено в соответствии с требованиями законодательства. Подробная информация размещена на
                    этикетке
                    или упаковке товара, либо в технической документации к нему.</></p>
            </div>
            <div>
                <h4>3. Регистрация на сайте и оформление заказа</h4>

                <p><>3.1. Заказ может быть оформлен Клиентом через оператора по телефону или самостоятельно через
                    форму Заказа на Сайте.</></p>

                <p><>3.2. Регистрация на Сайте не является обязательной для оформления Заказа. Покупатель может по
                    собственному желанию пройти процедуру регистрации на Сайте для доступа к дополнительным опциям
                    личного кабинета (история и отслеживание заказов, получение сведений об Акциях Продавца и
                    пр.).</></p>

                <p><>3.3. При регистрации на сайте клиент предоставляет в обязательном порядке информацию о себе:
                    имя, адрес электронной почты и пароль для доступа к Сайту, иные сведения указываются на усмотрение
                    Покупателя.</></p>

                <p><>3.4. Продавец не несет ответственность за точность и правильность информации, предоставляемой
                    клиентом при регистрации на Сайте и/или оформлении Заказа.</></p>

                <p><>3.5. Клиент обязуется не сообщать третьим лицам логин и пароль, указанные при регистрации на
                    Сайте. В случае возникновения у клиента подозрений относительно безопасности его логина и пароля или
                    возможности их несанкционированного использования третьими лицами, клиент обязуется незамедлительно
                    уведомить об этом Продавца, направив сообщение в разделе <Link to={"/contacts"}>«Обратная связь».
                </Link></></p>

                <p><>3.6. После оформления заказа Клиенту Продавцом предоставляется подтверждение заключения
                    договора по электронной почте и/или в текстовом сообщении на телефон. В подтверждении Клиенту
                    предоставляется информация о номере Заказа и ожидаемой дате передачи Заказа в Службу
                    доставки.</></p>

                <p>Дата доставки зависит от времени, необходимого на обработку заказа и доставку по адресу, указанному
                    Клиентом. При своем несогласии с планируемой датой доставки Покупатель вправе аннулировать Заказ,
                    сообщив об этом Продавцу.</p>

                <p>Обращаем внимание, что Продавец вправе ограничить продажу товара. Некоторые товары могут продаваться
                    с ограничениями - одна единица товара одному покупателю.</p>

                <p><>3.7. Заказ Клиента может быть аннулирован Продавцом (в том числе, после подтверждения Заказа
                    Клиенту) в следующих случаях:</></p>

                <ul>
                    <li>Товар отсутствует в наличии у Продавца (в том числе, закончился в результате выполнения ранее
                        поступивших Заказов);
                    </li>
                    <li>выполнение Заказа стало невозможным по причинам, независящим от Продавца (введение
                        ограничительных мер органами власти, невозможность доставки Товара по адресу, указанному
                        Клиентом, и пр.);
                    </li>
                    <li>при недобросовестном поведении Клиента, свидетельствующем об отсутствии намерения приобрести
                        Товар, включая предоставление недостоверных сведений, неоднократный срыв доставки в результате
                        невозможности Службы доставки связаться с Клиентом по прибытии на подтверждённый адрес,
                        приобретение Товара для перепродажи (в большом количестве);
                    </li>
                    <li>товар не может быть реализован по цене, указанной в Заказе, по причине исчерпания количества
                        Товара, выделенного Продавцом для реализации в рамках Акции;
                    </li>
                    <li>неполучение Заказа Клиентом в течение срока бронирования, предусмотренного для соответствующего
                        пункта самовывоза;
                    </li>
                    <li>в случае, если клиент приобретает товар с целью дальнейшей перепродажи третьим лицам.</li>
                </ul>

                <p><>3.8. В случае аннулирования Заказа Продавец осуществляет возврат денег, полученных от Клиента
                    за Товар, в течение 3 дней. Клиент вправе оформить новый Заказ, который должен быть оплачен
                    отдельно. Если на момент оформления заказа цена товара увеличилась, а предыдущий заказ был
                    аннулирован не по причине брака, компенсация разницы в цене на день оформления нового заказа не
                    начисляется.</></p>
            </div>
            <section>
                <h4>4. Доставка</h4>

                <p><>4.1. Способы доставки товаров указаны на Сайте в соответствующем разделе. Обязанность
                    Продавца передать товар Покупателю считается исполненной в момент доставки Товара Получателю либо в
                    заранее оговоренный пункт самовывоза.</></p>

                <p><>4.2. Доставка Товара по местонахождению Покупателя, осуществляется силами службы доставки,
                    назначенной Продавцом по адресу, указанному Покупателем.</></p>

                <p><>4.3. Заказ вручается Покупателю либо назначенному им получателю, либо лицу, предоставившему
                    документ, подтверждающий оформление Заказа и оплатившему Товар (при отсутствии предварительной
                    оплаты). Во избежание случаев мошенничества, при вручении предоплаченного Заказа у получателя может
                    быть затребован документ, удостоверяющий личность. При отсутствии данного документа либо
                    несовпадения указанных в нем сведений с деталями заказа или данными Покупателя, в передаче данного
                    Товара может быть отказано.</></p>

                <p><>4.4. Момент получения Заказа Покупатель осуществляет приемку Товара по целостности упаковки,
                    внешнему виду, количеству, ассортименту и комплектности. В случае отсутствия претензий к
                    доставленному Товару Получатель расписывается в сопровождающей документации к Заказу и оплачивает
                    Заказ (при отсутствии предоплаты). Подпись получателя Товара в сопроводительных документах (в том
                    числе, в маршрутной или доставочной квитанции и иных документах) подтверждает отсутствие внешних
                    видимых дефектов Товара при его передаче Продавцом Получателю. При принятии Заказа от службы
                    доставки, Покупатель обязан осмотреть доставленный Товар и проверить его на соответствие заявленному
                    количеству, ассортименту и комплектности Товара, а также проверить срок службы доставленного Товара
                    и целостность упаковки. В случае отсутствия претензий к доставленному Товару Получатель
                    расписывается в доставочной квитанции либо ином аналогичном документе, предоставляемом службой
                    доставки, и оплачивает Заказ (в отсутствие предоплаты). Подпись в доставочных документах
                    свидетельствует о том, что претензий к Товару получателем не заявлено и Продавец полностью и
                    надлежащим образом выполнил свою обязанность по передаче Товара.</></p>

                <p><>4.5. При передаче товара в доставку правила передачи товара и правила подтверждения факта
                    получения покупателем товара регулируются правилами службы доставки. В случае, если покупатель
                    желает заменить назначенную продавцом службу доставки он вправе согласовать с продавцом
                    соответствующую замену (если это возможно) либо выбрать иной способ получения товара
                    (самовывоз).</></p>

                <p><>4.6. В одном Заказе на доставку допускается не более 10 единиц товаров. В случае,
                    если в Заказе больше 10 единиц, необходимо оформить несколько заказов.</></p>

                {/*<p><>4.7. Примерка товара при курьере возможна только при оформлении заказа с оплатой курьеру. При*/}
                {/*    предоплате заказа на сайте или в мобильном приложении невозможен частичный выкуп заказа, примерка и*/}
                {/*    возврат товара, если у покупателя нет претензий к доставленному товару.</></p>*/}

            </section>
            <section>
                <h4>5. Оформление предзаказа</h4>

                <p><>5.1. Продавцом на сайте не предусмотрены предварительные заказы (предзаказы) на Товары,
                    которые еще не поступили в продажу и не находятся на складе Продавца.</></p>

                <p><>5.2. Все Товары, размещенные на Сайте, находятся на складе Продавца и доступны для покупки в
                    режиме реального времени.</></p>

                <p><>5.3. Продавец не информирует Покупателей о запланированных сроках поступления Товаров в
                    продажу, так как все Товары доступны для приобретения немедленно при их наличии на складе.</>
                </p>

                <p><>5.4. В случае изменения статуса Товара на сайте (например, отсутствие в наличии), информация
                    о таких изменениях предоставляется Покупателю незамедлительно на указанный им электронный адрес или
                    телефонный номер.</></p>
            </section>
            <section>
                <h4>6. Оплата товара</h4>

                <p><>6.1. Цена Товара указывается на Сайте в рублях РФ.</></p>

                <p><>6.2. Цена Товара и окончательная сумма Заказа с учетом стоимости доставки и применимых
                    скидок, указывается на этапе оформления Заказа и действительна на момент нажатия кнопки "Оплатить
                    сейчас".</></p>

                <p><>6.3. К Заказу применяется способ оплаты, выбранный Покупателем при оформлении Заказа из
                    вариантов, предложенных Продавцом на платежном шлюзе YooKassa.</></p>
            </section>
            <section>
                <h4>7. Обмен и возврат</h4>

                <p><>7.1. Обмен и возврат товара осуществляется в соответствии с Законом РФ от 07.02.1992 N 2300-1
                    «О защите прав потребителей» и Постановлением Правительства РФ от 31.12.2020 N 2463 «Об утверждении
                    Правил продажи товаров по договору розничной купли-продажи…».</></p>

                <p><>7.2. Возврат товара надлежащего качества:</></p>
                <ul>
                    <li>Покупатель вправе отказаться от Товара в любое время до его передачи, и в течение 7 дней после
                        покупки.
                    </li>
                    <li>Возврат товара надлежащего качества возможен при сохранении его товарного вида, потребительских
                        свойств и наличия документа, подтверждающего факт и условия покупки. Отсутствие указанного
                        документа не лишает Покупателя возможности предоставить другие доказательства приобретения
                        Товара у Продавца.
                    </li>
                    <li>При возврате Товара надлежащего качества, если при покупке выдавался подарок, подарок также
                        должен быть возвращен в надлежащем состоянии.
                    </li>
                </ul>

                <p><>7.3. Возврат товара ненадлежащего качества:</></p>
                <ul>
                    <li>Покупатель, которому продан Товар ненадлежащего качества, вправе потребовать безвозмездного
                        устранения недостатков, соразмерного уменьшения цены, замены на аналогичный товар или возврата
                        уплаченной суммы в соответствии с Законом.
                    </li>
                </ul>

                <p><>7.4. При отказе Покупателя от Товара Продавец обязан вернуть уплаченную сумму
                    не позднее чем через 10 дней с момента предъявления соответствующего
                    требования Покупателем.</></p>

                <p><>7.5. Требования Покупателей оформляются в письменном виде и направляются по юридическому
                    адресу Продавца.</></p>
            </section>
        </Container>
    )
};

export default Offer;