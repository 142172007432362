import React from 'react';
import Core from "./Core";
import "./i18n";
import "./index.css";

const App = () => {
    return (
        <Core />
    )
}

export default App;